<template>
  <div class="content" style="height: 100%">
    <pulse-loader style="position: relative; top: 50%; left: 50%;" color="#6177FF" />
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  name: "Home",
  components: {PulseLoader},
  mounted() {
    if(process.env.VUE_APP_DEMO == 0) {
      window.location.href = 'https://' + process.env.VUE_APP_LK_API + '/ru/login'
    }
  }
}
</script>

<style scoped>

</style>