<template>
  <span id="trainingTest">
    <span v-if="test">
    <div v-if="test.status === 'active'" class="collapse-card mt-3" >
      <div class="collapse-card__header">
        <div class="row">
          <div class="col">
            <div class="collapse-card__title" v-b-toggle.collapseExampleTest data-toggle="collapse" href="#collapseExampleTest" role="button"
                 aria-expanded="false" aria-controls="collapseExampleTest">Итоговый тест по курсу
            </div>
          </div>
          <div class="col-auto">
            <div class="collapse-card__status collapse-card__in_progress">Завершено на {{ Math.ceil(100 * answeredLength/test.questionsCount) }}%</div>
            <!-- in progress, complete-->
          </div>
          <div class="col-auto">
            <vue-countdown  :time="getTime()" :transform="transform">
              <template slot-scope="props">
                <div class="collapse-card__duration">{{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}</div>
              </template>
            </vue-countdown>
          </div>
        </div>
      </div>
      <b-collapse id="collapseExampleTest" v-model="hasTest">
        <div class="collapse-card__body">
          <div class="content_500 test-name mb-2">{{ test.title }}</div>
          <div class="d-inline-flex content_500 test-stat mb-3">
            <span class="mr-2">Ответов:</span>
            <span class="content_success">{{ answeredLength }}</span>/<span>{{ test.questionsCount }}</span>
          </div>
          <div class="test-pager">
            <!-- test-pager__item_current, test-pager__item_error, test-pager__item_complete -->
            <a href="javascript:void(0)" v-for="(question, key) in test.questions" :key="key" @click="setCurrent(key)" class="test-pager__item" :class="{'test-pager__item_current': currentKey === key, 'test-pager__item_complete': cQueue[question.id].answer}">
              {{ key + 1 }}
            </a>
          </div>
          <template v-if="cQueue">
            <div v-for="(question, key) in test.questions" :key="key" class="test-item">
            <template v-if="currentKey === key">
              <div class="test-item__title content_500 mb-2" v-html="question.title"></div>
              <div v-if="!question.multiple && !question.typed" class="form-radios">
                <div class="form-radio" v-for="(answer, indexKey) in question.answers" :key="indexKey">
                  <div class="form-radio__wrapper">
                    <input type="radio" :name="question.id" @change="sendQueue()" :value="answer.id" :id="key+'_'+indexKey" v-model="cQueue[question.id].answer" />
                    <label :for="key+'_'+indexKey" v-html="answer.title"></label>
                  </div>
                </div>
              </div>
              <div v-else-if="question.multiple" class="form-radios">
                <div class="form-checkbox" v-for="(answer, indexKey) in question.answers" :key="indexKey">
                  <div class="form-checkbox__wrapper">
                    <input type="checkbox" @change="sendQueue()" :value="answer.id" :id="key+'_'+indexKey" v-model="cQueue[question.id].answer[answer.id]" />
                    <label :for="key+'_'+indexKey" v-html="answer.title"></label>
                  </div>
                </div>
              </div>
            </template>
          </div>
          </template>
        </div>
        <div class="collapse-card__footer">
          <div class="row justify-content-center">
            <div class="col-auto">
              <button @click="prev()" class="btn btn-outline-primary">Предыдущий</button>
            </div>
            <div class="col">
              <button @click="next()" class="btn btn-outline-primary">Следующий</button>
            </div>
            <div class="col-auto">
              <button @click="sendQueue(true)" class="btn btn-primary">
                <b-spinner v-if="startLoading" />
                <span :class="{opacity: startLoading}">
                  Завершить
                </span>
              </button>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="collapse-card mt-3" v-if="test.status === 'finished'">
      <div class="collapse-card__header" v-b-toggle.collapseExampleTest>
        <div class="row">
          <div class="col">
            <div class="collapse-card__title" data-toggle="collapse" href="#collapseExampleTest" role="button"
                 aria-expanded="false" aria-controls="collapseExampleTest">Итоговый тест по курсу
            </div>
          </div>
          <div class="col-auto">
            <div class="collapse-card__status collapse-card__status_complete">Пройден</div>
            <!-- in progress, complete-->
          </div>
        </div>
      </div>
      <b-collapse id="collapseExampleTest" v-model="hasTest">
        <div class="collapse-card__body">
          <div class="d-inline-flex content_500 test-stat mb-3">
            <span class="mr-2">Правильных ответов:</span>
            <span class="content_success">{{ test.user_results.correct_count }}</span>/<span>{{ test.questionsCount }}</span>
          </div>
          <template v-if="test.isShowResult">
            <div class="test-pager">
            <a href="#" class="test-pager__item test-pager__item_complete">1</a>
            <a href="#" class="test-pager__item test-pager__item_complete">2</a>
            <a href="#" class="test-pager__item test-pager__item_complete">3</a>
            <a href="#" class="test-pager__item test-pager__item_complete">4</a>
            <a href="#" class="test-pager__item test-pager__item_complete">5</a>
            <a href="#" class="test-pager__item test-pager__item_error">6</a>
            <a href="#" class="test-pager__item test-pager__item_complete">7</a>
            <a href="#" class="test-pager__item test-pager__item_error">8</a>
            <a href="#" class="test-pager__item test-pager__item_complete">9</a>
            <a href="#" class="test-pager__item test-pager__item_complete">10</a>
            <a href="#" class="test-pager__item test-pager__item_current">11</a>
          </div>
            <div class="test-item">
            <div class="test-item__suptitle mb-1">Выберите правильный вариант ответа. Вопрос:</div>
            <div class="test-item__title content_500 mb-2">Как расшифровывается экспорт B2B?</div>

            <form>
              <div class="form-radios">
                <div class="form-radio">
                  <div class="form-radio__wrapper">
                    <input type="radio" name="arr[]" value="" id="arr-1" />
                    <label>Экпорт в иностранное государство с целью розничной продажи</label>
                  </div>
                </div>
                <div class="form-radio">
                  <div class="form-radio__wrapper">
                    <input type="radio" name="arr[]" value="" id="arr-2" />
                    <label>Экспорт с целю хранения и перепродажи в розницу товаров</label>
                  </div>
                </div>
                <div class="form-radio">
                  <div class="form-radio__wrapper">
                    <input type="radio" name="arr[]" value="" id="arr-3" />
                    <label>Целевой экспорт</label>
                  </div>
                </div>
                <div class="form-radio">
                  <div class="form-radio__wrapper">
                    <input type="radio" name="arr[]" value="" id="arr-4" />
                    <label>Экспорт по схеме бизнес для клиента (товары для физических лиц)</label>
                  </div>
                </div>
                <div class="form-radio">
                  <div class="form-radio__wrapper">
                    <input checked type="radio" name="arr[]" value="" id="arr-5" />
                    <label>Экспорт по схеме бизнес для бизнеса  (товары для юридических лиц)</label>
                  </div>
                </div>
              </div>
            </form>
            <div class="test-item__description content_success">Правильный ответ!</div>

          </div>
          </template>
        </div>
      </b-collapse>
    </div>
    <div class="collapse-card mt-3" v-if="test.status === 'in_started'">
      <div class="collapse-card__header" v-b-toggle.collapseExampleTest>
        <div class="row">
          <div class="col">
            <div class="collapse-card__title" data-toggle="collapse" href="#collapseExampleTest" role="button"
                 aria-expanded="false" aria-controls="collapseExampleTest"> {{test.title}}
            </div>
          </div>
        </div>
      </div>
      <b-collapse id="collapseExampleTest" v-model="hasTest">
        <div class="collapse-card__body">
          <div class="test-name content_500 mb-5">{{test.description}}</div>
          <div class="row content_500 test-data mb-5">
            <div class="col-auto test-data__duration"><span>{{ test.duration }} минут</span></div>
            <div class="col-auto test-data__questions"><span>{{ test.questionsCount }} вопросов</span></div>
            <div class="col-auto test-data__reworks"><span>{{ test.retakes }} пересдач</span></div>
          </div>

          <div class="test-preamula">
            <p>Тест состоит из {{ test.questionsCount }} вопросов.</p>
            <p>На тест отведено ограниченное количество времени.</p>
            <p class="mb-4">Вопросы, на которые вы не успеете ответить, будут засчитаны, как неправильные.</p>
            <p>Некоторые вопросы могут иметь несколько вариантов ответа.</p>
          </div>

        </div>
        <div class="collapse-card__footer">
          <div class="row justify-content-start">
            <div class="col-auto">
              <button @click="startTest()" :disabled="startLoading" class="btn btn-primary">
                <b-spinner v-if="startLoading" />
                <span :class="{opacity: startLoading}">
                  Начать тест
                </span>
              </button>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div  v-if="test.status === 'solved'" class="collapse-card mt-3">
      <div class="collapse-card__header" v-b-toggle.collapseExampleTest>
        <div class="row">
          <div class="col">
            <div class="collapse-card__title" data-toggle="collapse" href="#collapseExampleTest" role="button"
                 aria-expanded="false" aria-controls="collapseExampleTest">{{ test.title }}
            </div>
          </div>
        </div>
      </div>
      <b-collapse id="collapseExampleTest" v-model="hasTest">
        <div class="collapse-card__body">
          <div class="content_500 test-name mb-2">{{test.description}}</div>
          <div class="text-center">
            <div class="test-ok mb-2">
              <img src="/img/icon-ok.svg" />
            </div>
            <div class="test-final mb-1">Тест завершен</div>
            <div class="d-inline-flex content_500 test-stat mb-3">
              <span class="mr-1">Правильных ответов:</span>
              <span>{{ test.user_results.correct_count }}</span>/<span>{{ test.questionsCount }}</span>
            </div>
            <br/>
            <div v-if="tryCount" class="d-inline-flex content_500 test-stat mb-3">
              <span class="mr-1">Осталось попыток:</span>
              <span>{{ tryCount }}</span>
            </div>
            <div class="test-result mb-5">
              Для того, чтобы получить сертификат о пройденном обучении, просим вас оставить отзыв
            </div>
          </div>
        </div>
        <div class="collapse-card__footer">
          <div class="row justify-content-center">
            <div class="col-auto">
              <button class="btn btn-info" @click="startTest()">
                <b-spinner v-if="startLoading" />
                <span :class="{opacity: startLoading}">
                  Пересдать тест
                </span>
              </button>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </span>
  </span>
</template>

<script>
import ApiService from "../services/api.service";
import {TokenService} from "../services/token.service";
import VueCountdown from '@chenfengyuan/vue-countdown';
import moment from "moment";
import {eventBus} from "../main";

//import _ from 'lodash';
export default {
  name: "Test",
  props: ['testId'],
  components: {VueCountdown},
  data() {
    return {
      test: null,
      startLoading: false,
      startLoadingSend: false,
      currentKey: 0,
      cQueue: null,
      hasTest: false
    }
  },
  mounted() {
    ApiService.get('api/tests/' + this.testId, {
      params: {
        access_token: TokenService.getExportUser()
      },
      withCredentials: false
    }).then(res => {
      this.test = res.data
      if(this.test.status === 'finished' || this.test.status === 'solved') {
        eventBus.$emit('test:solved')
        eventBus.$emit('test:finish')
      }
      this.cQueue = this.prepareTest.cQueue
      this.answeredLength = this.prepareTest.answeredLength
      if(window.location.hash === '#test') {
        this.gotoTest()
      } else {
        this.goto()
      }
    })
  },
  methods: {
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        const digits = value < 10 ? `0${value}` : value;
        props[key] = `${digits}`;
      });

      return props;
    },
    next() {
      this.currentKey = this.currentKey + 1
      if(this.currentKey > (this.test.questionsCount-1)) {
        this.currentKey = 0;
      }
    },
    prev() {
      this.currentKey = this.currentKey - 1
      if(this.currentKey < 0) {
        this.currentKey = this.test.questionsCount-1;
      }
    },
    setCurrent(key) {
      this.currentKey = key
      return false;
    },
    getTime() {
      const end  = moment(this.test.start).add(this.test.duration, 'minutes')
      const start = moment()
      return end - start
    },
    startTest() {
      this.startLoading = true
      ApiService.post('api/tests/' + this.testId + '/starts?access_token=' + TokenService.getExportUser(), {}, false).then(res => {
        this.test = res.data
        this.startLoading = false
        this.cQueue = this.prepareTest.cQueue
        this.answeredLength = this.prepareTest.answeredLength
      })
    },
    goto() {
      window.scrollTo(0, 0);
    },
    gotoTest() {
      this.hasTest = true
      const ele = document.getElementById('trainingTest')
      console.log(ele.getBoundingClientRect())
      window.scrollTo(0, ele.getBoundingClientRect().y);
    },
    unJoin(answer) {
      let ret = false;
      if(typeof answer === 'string') {
        answer = answer.split(',');
        ret = {};
        answer.forEach((val) => {
          ret[val] = true;
        });
      }
      return ret;
    },
    arrayKeys(array) {
      let keys = [];
      for (const [val, key] of Object.entries(array)) {
        if(key) {
          keys.push(val);
        }
      }
      return keys;
    },
    sendQueue(finish = false) {
      let queue = [],
          length = 0;
      for (const [question, value] of Object.entries(this.cQueue)) {
        if(value.answer) {
          const answer = JSON.parse(JSON.stringify(value.answer));
          queue.push({
            question:  question,
            answer: typeof answer === 'object'
                ? this.arrayKeys(answer).join()
                : answer,
            answer_id: typeof answer === 'number'
                ? answer : null,
          })
          length++;
        }
      }
      if(!length) {
        return;
      }

      if (finish) {
        this.startLoading = true
        ApiService.post('api/tests/' + this.test.id + '/finishes?access_token=' + TokenService.getExportUser(), {queue: queue }, false).then((res) => {
          this.test = res.data;
          this.startLoading = false
          eventBus.$emit('test:solved')
          eventBus.$emit('test:finish')
        });
      } else {
        ApiService.post('api/tests/' + this.test.id + '?access_token=' + TokenService.getExportUser(),{queue: queue }, false).then((res) => {
          this.answeredLength = length;
          if(res.status === 208) {
            this.startLoading = true
            ApiService.post('api/tests/' + this.test.id + '/finishes?access_token=' + TokenService.getExportUser(), {queue: queue }, false).then((res) => {
              this.test = res.data;
              this.startLoading = false
            });
          }
          if (res.data.timer) {
            this.test.timer = res.data.timer;
          }
        })
      }
    }
  },
  computed: {
    tryCount() {
      return this.test.retakes - this.test.user_results.retakes;
    },
    prepareTest() {
      const questions = this.test.questions
      let cQueue = {},
          length = 0;

      if(!questions || !questions.length) {
        return {
          queue: [],
          answeredLength: 0
        }
      }

      // Установка объектов ответа на вопросы
      questions.forEach((question) => {
        if(question.user) {
          if(question.multiple) {
            cQueue[question.id] = {
              answer: this.unJoin(question.user.answer)
            }
          } else if(question.typed) {
            cQueue[question.id] = {
              answer: question.user.answer
            }
          } else {
            cQueue[question.id] = {
              answer: question.user.answer_id
            }
          }
          length++;
        } else {
          if(question.multiple) {
            cQueue[question.id] = {
              answer: {}
            };
          } else {
            cQueue[question.id] = {};
          }
        }
      })
      return {
        cQueue: cQueue,
        answeredLength: length
      }
    }
  }
}
</script>