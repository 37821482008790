<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a :href="`https://${link}/ru/main`">Главная</a></li>
      <li class="breadcrumb-item"><a :href="`https://${link}/ru/educational-services`">Образовательные сервисы</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ title }}</li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: ['title'],
  data() {
    return {
      link: process.env.VUE_APP_LK_API
    }
  }
}
</script>

<style scoped>

</style>