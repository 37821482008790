<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" :href="link">Информация и статус услуги</a>
    </li>
    <li class="nav-item">
      <a class="nav-link active" href="#">Материалы курса</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Tabs",
  props: ['link']
}
</script>

<style scoped>

</style>