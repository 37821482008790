<template>
  <div class="content">
    <template v-if="seminar">
      <Breadcrumbs :title="seminar.title"/>
      <div class="row mb-3 mb-xl-6">
        <div class="col-12 col-xl">
          <h2 class="content-medium">{{ seminar.title }}</h2>
        </div>
        <div class="col-auto mt-1 mt-xl-0">
          <a href="https://lk.exportcenter.ru/ru/educational-services" class="btn btn-primary btn_title-next">Образовательные сервисы</a>
        </div>
      </div>
      <Tabs v-if="this.seminar.is_participant && this.seminar.is_participant.external_link" :link="this.seminar.is_participant.external_link"/>
      <Tabs v-else/>
      <div class="seminar-about my-3">
        <div class="content_20 mb-1">Об этом семинаре</div>
        <span v-html="seminar.course.full_desc"></span>
        <div class="content_20 mb-1">Информация от организаторов</div>
        <span v-html="seminar.description"></span>
      </div>
      <div class="row">
        <div class="col-xl-8 col-lg-9 col-md-11">
          <div class="seminar-materials">
            <div class="content_20 mb-2">Материалы семинара</div>
            <div class="collapse-card">
              <div class="collapse-card__header" v-b-toggle.collapseExample>
                <div class="row">
                  <div class="col">
                    <div class="collapse-card__title" data-toggle="collapse" href="#collapseExample" role="button"
                         aria-expanded="false" aria-controls="collapseExample">Рабочая тетрадь
                    </div>
                  </div>
                </div>
              </div>
              <b-collapse v-if="seminar.course && seminar.course.brochure" class="collapse show" id="collapseExample">
                <div class="collapse-card__body">
                  <div class="content_22 content_medium mb-2">Рабочая тетрадь</div>
                  <div class="file-info">
                    <div class="file-info__icon"><img src="/img/icon-pdf.svg" /></div>
                    <div class="file-info__content">
                      <div class="file-info__name content_500"><a target="_blank" :href="seminar.course.brochure.file">{{ seminar.course.brochure.name }}</a></div>
                      <div class="file-info__details content_12">{{seminar.course.brochure.ext}}, {{seminar.course.brochure.size}} МБ</div>
                    </div>
                  </div>
                </div>
                <div class="collapse-card__footer">
                  <div class="row justify-content-start">
                    <div class="col">
                      <a :href="seminar.course.brochure.file" target="_blank" class="btn btn-primary">Скачать</a>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
          <template v-if="!seminar.is_without_tests && seminar.control_test">
            <test :test-id="seminar.control_test.id" />
          </template>
          <div class="row" v-if="seminar && seminar.is_participant && seminar.is_participant.was">
            <div class="col-xl-8 col-lg-9 col-md-11">
              <div class="row justify-content-center" style="margin-top: 20px;">
                <button class="btn btn-primary" @click="senOrder()">
                  <b-spinner v-if="loadButton" />
                  <span :class="{opacity: loadButton}">
                    Перейти к отзыву
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content_20 my-3">Вы можете выбрать другой семинар</div>
      <div class="carousel-wrapper" v-if="courses">
        <carousel :responsive="{0:{items:1,nav:false,dots:false},600:{items:3,nav:false,dots:true}, 960:{items:4,nav:false,dots:true}, 1400:{items:5,nav:false,dots:true}}" :loop="true" :nav="true" class="carousel-courses">
          <template slot="prev"><span class="prev"></span></template>
          <div class="item" v-for="(course, key) in courses" :key="key">
            <div class="carousel-courses__item">
              <div class="carousel-courses__item-img" v-if="course.preview" :style="{backgroundImage: 'url(https:' + course.preview['500x'] + ')'}"></div>
              <div class="carousel-courses__item-img" v-else :style="{backgroundImage: 'url(/img/course-bg.png)'}"></div>
              <div class="carousel-courses__item-type">Экспортные семинары</div>
              <div class="carousel-courses__item-title">{{course.title}}</div>
              <div class="carousel-courses__item-link"><a :href="domain + course.event_ow_link">Перейти к обучению</a></div>
            </div>
          </div>
          <template slot="next"><span class="next"></span>
          </template>
        </carousel>
      </div>
    </template>
    <template v-else>
      <pulse-loader style="position: relative; top: 50%; left: 50%;" color="#6177FF" />
    </template>
  </div>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs";
import Tabs from "../components/Tabs";
import ApiService from "../services/api.service";
import carousel from 'vue-owl-carousel'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import _ from "lodash";
import {TokenService} from "../services/token.service";
import Test from "../components/Test";
import {eventBus} from "../main";
export default {
  name: "Seminar",
  components: {Test, Tabs, Breadcrumbs, carousel, PulseLoader},
  data() {
    return {
      courses: null,
      seminar: null,
      loadButton: false,
      domain: process.env.VUE_APP_PORTAL_LINK
    }
  },
  methods: {
    senOrder() {
      this.loadButton = true
      window.location.href = this.seminar.is_participant.external_link + '?feedback'
    },
    load() {
      ApiService.get('api/courses?limit=15&program=5', {withCredentials: false}).then(res => {
        this.courses = res.data.courses
        let seminarId = this.$route.params.id ? this.$route.params.id : this.courses[0].id;
        ApiService.get('ow/eventparticipants/' + seminarId, {withCredentials: false, params: {user: TokenService.getExportUserId()}}).then(res => {
          this.seminar = res.data
          this.courses = _.sortBy(this.courses, ['is_new']);
          this.courses = _.filter(this.courses, (item) => {
            return item.id != this.seminar.course.id
          });
        })
      })
    }
  },
  mounted() {
    if(process.env.VUE_APP_DEMO == 0) {
      eventBus.$on('auth', () => {
        this.load()
      })
    } else {
      this.load()
    }
  }
}
</script>